import { forwardRef } from 'react';
import { Text as RNText, StyleSheet } from 'react-native';
import type { TextProps } from 'react-native';

import { useTheme } from '../Theme/ThemeProvider';

export const Text = forwardRef(function Text(props: TextProps, ref: React.Ref<RNText>) {
  const theme = useTheme();

  return (
    <RNText
      {...props}
      ref={ref}
      style={[
        styles.default,
        {
          color: theme.base.text,
        },
        props.style,
      ]}
    />
  );
});

const styles = StyleSheet.create({
  default: {
    fontFamily: 'InnovigoMain',
  },
});
