import { CookieStorage } from 'amazon-cognito-identity-js';

import { CognitoAuthClient } from './CognitoAuthClient';
import { IAuthClient } from './types';

export function createAuthClient(config: any): IAuthClient {
  const domain = typeof window !== 'undefined' ? window.location.hostname : config.domain;
  const authClient = new CognitoAuthClient({
    storage: new CookieStorage({
      domain,
      secure: domain !== 'localhost' && !/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.exec(domain ?? ''),
    }),
    userPoolId: config.userPoolId,
    userPoolClientId: config.userPoolClientId,
  });
  return authClient;
}
