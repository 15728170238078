import { createContext, useContext, useEffect } from 'react';

export const COLOR_SCHEME_NAMES = ['light', 'dark'] as const;
export type ColorSchemeName = typeof COLOR_SCHEME_NAMES[number];
export function isColorSchemeName(value: string): value is ColorSchemeName {
  return (COLOR_SCHEME_NAMES as readonly string[]).includes(value);
}

const ColorSchemeContext = createContext<ColorSchemeName>('dark');

export function ColorSchemeProvider({
  children,
  colorScheme,
}: {
  children?: React.ReactNode;
  colorScheme: ColorSchemeName;
}) {
  // Set theme data on html
  useEffect(() => {
    if (typeof document === 'undefined') return;
    const html = document.querySelector('html');
    if (!html) return;
    html.dataset.theme = `theme-${colorScheme}`;
  }, [colorScheme]);

  return <ColorSchemeContext.Provider value={colorScheme}>{children}</ColorSchemeContext.Provider>;
}

export function useColorScheme() {
  const colorScheme = useContext(ColorSchemeContext);
  return colorScheme;
}
