import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { Text } from './Text';

export function LoadingPage({ debugText }: { debugText?: string }) {
  return (
    <View style={styles.container}>
      <ActivityIndicator />
      {process.env.NODE_ENV === 'development' && debugText ? <Text>{debugText}</Text> : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
