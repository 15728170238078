import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';
import type { PressableProps } from 'react-native';

import { Icon, IconProps } from '../Icon';
import { Text } from '../Text';
import { useTheme } from '../Theme/ThemeProvider';
import type { DerivedThemeForColorScheme } from '../Theme/types';

type PressableState = Readonly<{
  focused?: boolean;
  hovered?: boolean;
  pressed: boolean;
}>;

export type ButtonProps = PressableProps & {
  icon?: IconProps['name'];
  loading?: boolean;
  title?: string;
  variant?: keyof DerivedThemeForColorScheme['button'];
};

export function Button({
  icon,
  loading,
  style,
  title,
  variant = 'primary',
  ...pressableProps
}: ButtonProps) {
  const theme = useTheme();

  return (
    <Pressable
      {...pressableProps}
      disabled={pressableProps.disabled || loading}
      style={(state: PressableState) => {
        const { hovered, pressed } = state;
        const buttonType = pressed ? 'pressing' : hovered ? 'hover' : 'default';
        const colors = theme.button[variant][buttonType];
        return [
          styles.pressable,
          {
            backgroundColor: colors.background,
            borderColor: colors.border,
          },
          pressableProps.disabled && {
            backgroundColor: theme.neutral['04'],
            borderColor: theme.neutral['04'],
          },
          typeof style === 'function' ? style(state) : style,
        ];
      }}
    >
      {({ hovered, pressed }: PressableState) => {
        if (loading) {
          return <ActivityIndicator />;
        }
        const buttonType = pressed ? 'pressing' : hovered ? 'hover' : 'default';
        const colors = theme.button[variant][buttonType];
        return (
          <>
            {icon ? <Icon name={icon} color={colors.text} size={24} /> : null}
            {icon && title ? <View style={styles.spacer} /> : null}
            {title ? (
              <Text
                style={[
                  styles.titleText,
                  {
                    color: colors.text,
                  },
                ]}
              >
                {title}
              </Text>
            ) : null}
          </>
        );
      }}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pressable: {
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    borderWidth: 2,
    height: 48,
    paddingHorizontal: 24,
  },
  spacer: {
    flexShrink: 0,
    width: 8,
  },
  titleText: {
    fontSize: 15,
    lineHeight: 24,
  },
});
