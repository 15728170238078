import {
  ColorSchemeName,
  ColorSchemeProvider,
  isColorSchemeName,
} from '@innovigo/ui/components/ColorScheme';
import { useSystemColorScheme } from '@innovigo/ui/hooks/useSystemColorScheme';
import { createContext, useContext, useEffect } from 'react';

import { StorageStateStatus, useStorageState } from '../hooks/useStorageState';

type ColorSchemePreference = ColorSchemeName | undefined;

const STORAGE_KEY = 'innovigo/frontend-utils:colorSchemePreference';

const ColorSchemePreferenceContext = createContext<ColorSchemePreference>(undefined);
const SetColorSchemePreferenceContext = createContext<
  ((newPreference: ColorSchemePreference) => Promise<void>) | null
>(null);

export function ColorSchemePreferenceProvider({ children }: { children?: React.ReactNode }) {
  const systemColorScheme = useSystemColorScheme();
  const {
    data: storedColorSchemePreference,
    load: loadStoredColorSchemePreference,
    save: setColorSchemePreference,
    status,
  } = useStorageState<string | undefined>(STORAGE_KEY);
  useEffect(() => {
    loadStoredColorSchemePreference();
  }, [loadStoredColorSchemePreference]);

  if (status === StorageStateStatus.LOADING) return null;

  const colorSchemePreference =
    storedColorSchemePreference && isColorSchemeName(storedColorSchemePreference)
      ? storedColorSchemePreference
      : undefined;
  const currentColorScheme = colorSchemePreference ?? systemColorScheme;

  return (
    <ColorSchemePreferenceContext.Provider value={colorSchemePreference}>
      <SetColorSchemePreferenceContext.Provider value={setColorSchemePreference}>
        <ColorSchemeProvider colorScheme={currentColorScheme}>{children}</ColorSchemeProvider>
      </SetColorSchemePreferenceContext.Provider>
    </ColorSchemePreferenceContext.Provider>
  );
}

export function useColorSchemePreference() {
  const colorSchemePreference = useContext(ColorSchemePreferenceContext);
  return colorSchemePreference;
}

export function useSetColorSchemePreference() {
  const setColorSchemePreference = useContext(SetColorSchemePreferenceContext);
  if (!setColorSchemePreference) {
    throw new Error(
      'useSetColorSchemePreference must be used inside ColorSchemePreferenceProvider',
    );
  }
  return setColorSchemePreference;
}
