import { impossible } from '@innovigo/types';
import { useColorScheme } from '@innovigo/ui/components/ColorScheme';

import DummyDarkLogo from './DummyDarkLogo';
import DummyLightLogo from './DummyLightLogo';

export function Logo() {
  const colorScheme = useColorScheme();

  return colorScheme === 'dark' ? (
    <DummyDarkLogo />
  ) : colorScheme === 'light' ? (
    <DummyLightLogo />
  ) : (
    impossible(colorScheme)
  );
}
