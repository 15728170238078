import { Range } from '@innovigo/types';

import { ColorSchemeName } from '../../components/ColorScheme';

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX =
  | `#${string}${string}${string}${string}${string}${string}`
  | `#${string}${string}${string}`;

export type Color = RGB | RGBA | HEX;

type SecondaryColorIndex = `0${Range<1, 4>}`;
type SecondaryColors = Record<SecondaryColorIndex, Color>;
export type ThemeForColorScheme = {
  background: Color;
  primary: Color;
  secondary: SecondaryColors;
  danger: Color;
  success: Color;
  text: Color;
  neutralColorLightness: readonly number[];
};

export type Theme = Record<ColorSchemeName, ThemeForColorScheme>;

export const NEUTRAL_LIGHTNESS_DARK = [7, 11, 17, 22, 46, 94, 96, 98, 100] as const;
export const NEUTRAL_LIGHTNESS_LIGHT = [100, 97, 95, 93, 46, 22, 17, 11, 7] as const;
export type NeutralColors = Record<`0${Range<0, typeof NEUTRAL_LIGHTNESS_DARK['length']>}`, Color>;
type BackgroundColors = Record<`0${Range<0, 3>}`, Color>;
type ButtonColors = Record<
  'default' | 'hover' | 'pressing',
  {
    background: Color;
    border: Color;
    text: Color;
  }
>;

export type DerivedThemeForColorScheme = {
  base: {
    primary: Color;
    secondary: SecondaryColors;
    danger: Color;
    success: Color;
    text: Color;
  };
  neutral: NeutralColors;
  background: BackgroundColors;
  button: {
    primary: ButtonColors;
    secondary: ButtonColors;
  };
  input: {
    default: {
      label: Color;
      icon: Color;
      placeholder: Color;
      background: Color;
      border: Color;
      text: Color;
    };
    focused: {
      background: Color;
      border: Color;
      text: Color;
    };
  };
  switch: {
    thumb: Color;
    track: Color;
  };
};

export type DerivedTheme = Record<ColorSchemeName, DerivedThemeForColorScheme>;
