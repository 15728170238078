import { createContext, useContext, useEffect, useMemo } from 'react';
import { Platform } from 'react-native';

import { ColorSchemeName, useColorScheme } from '../../components/ColorScheme';
import { DEFAULT_DERIVED_THEME } from './default-theme';
import { buildDerivedTheme } from './derived-theme';
import { INNOVIGO_STYLE_ELE_ID, extractThemeStyles } from './extract-theme-style';
import { Theme } from './types';

const ThemeContext = createContext(DEFAULT_DERIVED_THEME);

export function ThemeProvider({ children, theme }: { theme: Theme; children?: React.ReactNode }) {
  const derivedTheme = useMemo(() => buildDerivedTheme(theme), [theme]);

  // For web we pass down css variable names in the context and set values in the dom
  const { theme: themeContextValue, stylesText } = useMemo(() => {
    if (Platform.OS !== 'web') {
      return {
        theme: derivedTheme,
        stylesText: undefined,
      };
    }
    return extractThemeStyles(derivedTheme);
  }, [derivedTheme]);
  useEffect(() => {
    if (!stylesText) return;
    const existingStyleEle = document.querySelector(`style#${INNOVIGO_STYLE_ELE_ID}`);
    if (existingStyleEle) {
      existingStyleEle.innerHTML = stylesText;
    } else {
      const styleEle = document.createElement('style');
      styleEle.id = INNOVIGO_STYLE_ELE_ID;
      styleEle.innerHTML = stylesText;
      document.head.appendChild(styleEle);
    }
  }, [stylesText]);

  return <ThemeContext.Provider value={themeContextValue}>{children}</ThemeContext.Provider>;
}

export function useTheme(colorScheme?: ColorSchemeName) {
  const derivedTheme = useContext(ThemeContext);
  const currentColorScheme = useColorScheme();
  return derivedTheme[colorScheme ?? currentColorScheme];
}
