import { Icon } from '@innovigo/ui/components/Icon';
import { Text } from '@innovigo/ui/components/Text';
import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { useNavigate } from 'react-router-dom';

export function TopBar() {
  const theme = useTheme();

  // Keep title in sync with document title
  const [title, setTitle] = useState(document.title);
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      setTitle(mutations[0].target.textContent ?? '');
    });
    observer.observe(document.querySelector('title')!, {
      subtree: true,
      characterData: true,
      childList: true,
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  const navigate = useNavigate();

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: theme.background['01'],
        },
      ]}
    >
      <View style={styles.titleContainer}>
        <Text style={styles.titleText}>{title}</Text>
      </View>
      <Pressable onPress={() => navigate(-1)} style={styles.leftContainer}>
        <Icon name="chevron-left" color={theme.base.secondary['01']} size={32} />
        <Text
          style={[
            styles.backText,
            {
              color: theme.base.secondary['01'],
            },
          ]}
        >
          Back
        </Text>
      </Pressable>
      <View></View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexShrink: 0,
    height: 96,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  titleText: {
    fontSize: 15,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  backText: {
    fontSize: 15,
    marginLeft: 4,
  },
  rightContainer: {
    flexDirection: 'row',
  },
});
