import { useAuth } from '@innovigo/frontend-utils/auth';
import { createContext, useContext, useMemo } from 'react';

import { RequireAuth } from '../RequireAuth';

export type CurrentOrgContextValue = {
  currentOrg: {
    id: string;
  };
};

const CurrentOrgContext = createContext<CurrentOrgContextValue | null>(null);

export function CurrentOrgProvider({ children }: { children?: React.ReactNode }) {
  const { userAttributes } = useAuth();

  // TODO: only support personal organization at the moment
  const contextValue = useMemo(() => {
    if (!userAttributes?.id) return null;
    return {
      currentOrg: {
        id: `pe_${userAttributes.id}`,
      },
    };
  }, [userAttributes?.id]);

  if (!contextValue) {
    return <RequireAuth>{children}</RequireAuth>;
  }

  return <CurrentOrgContext.Provider value={contextValue}>{children}</CurrentOrgContext.Provider>;
}

export function useCurrentOrg() {
  const contextValue = useContext(CurrentOrgContext);
  if (!contextValue) {
    throw new Error(`useCurrentOrg must be used inside CurrentOrgProvider`);
  }

  return contextValue.currentOrg;
}
