import { z } from 'zod';

import {
  buildCourseReleaseSchema,
  buildPublishedLessonSchema,
  buildPublishedModuleSchema,
} from './course-release';
import { DraftFileWithThumbnails } from './draft-file';

export const buildDraftLessonSchema = () =>
  buildPublishedLessonSchema().extend({
    defaultVideo: buildPublishedLessonSchema().shape.defaultVideo.unwrap().deepPartial().optional(),
  });
export type DraftLesson = z.infer<ReturnType<typeof buildDraftLessonSchema>>;

export const buildCreateDraftLessonInputSchema = () =>
  buildDraftLessonSchema().pick({
    title: true,
  });
export type CreateDraftLessonInput = z.infer<ReturnType<typeof buildCreateDraftLessonInputSchema>>;

export const buildUpdateDraftLessonInputSchema = () =>
  buildDraftLessonSchema()
    .pick({
      title: true,
      description: true,
      durationSeconds: true,
      defaultVideo: true,
      viewId: true,
      notes: true,
    })
    .partial();
export type UpdateDraftLessonInput = z.infer<ReturnType<typeof buildUpdateDraftLessonInputSchema>>;

export const buildDraftModuleSchema = () => buildPublishedModuleSchema();
export type DraftModule = z.infer<ReturnType<typeof buildDraftModuleSchema>>;

export const buildCreateDraftModuleInputSchema = () =>
  buildDraftModuleSchema().pick({
    title: true,
  });
export type CreateDraftModuleInput = z.infer<ReturnType<typeof buildCreateDraftModuleInputSchema>>;

export const buildUpdateDraftModuleInputSchema = () =>
  buildDraftModuleSchema()
    .pick({
      title: true,
      description: true,
      lessonIds: true,
    })
    .partial();
export type UpdateDraftModuleInput = z.infer<ReturnType<typeof buildUpdateDraftModuleInputSchema>>;

export const buildDraftCourseSchema = () =>
  buildCourseReleaseSchema()
    .omit({
      version: true,
    })
    .extend({
      orgId: z.string(),
      modules: z.array(buildDraftModuleSchema()),
      lessons: z.array(buildDraftLessonSchema()),
    });
export type DraftCourse = z.infer<ReturnType<typeof buildDraftCourseSchema>>;

export const buildCreateDraftCourseInputSchema = () =>
  buildDraftCourseSchema().pick({
    title: true,
  });
export type CreateDraftCourseInput = z.infer<ReturnType<typeof buildCreateDraftCourseInputSchema>>;

export const buildUpdateDraftCourseInputSchema = () =>
  buildDraftCourseSchema()
    .pick({
      title: true,
      description: true,
      coverImage: true,
      modules: true,
      lessons: true,
    })
    .partial();
export type UpdateDraftCourseInput = z.infer<ReturnType<typeof buildUpdateDraftCourseInputSchema>>;

export type DraftCourseWithCoverImage = Omit<DraftCourse, 'coverImage'> & {
  coverImage?: DraftFileWithThumbnails;
};
