import type { AxiosError } from 'axios';

import { ApiErrorResponse } from './types';

export class ApiError extends Error {
  constructor(message: string, public code: string) {
    super(message);
  }
}

export function buildApiError(err: any) {
  if (err.response) {
    console.log(err);
    const response = (err as AxiosError<ApiErrorResponse>).response!;
    if (response.status === 304) {
      return new ApiError('Not Modified', 'NotModified');
    }
    if (response.status >= 400) {
      return new ApiError(
        response.data?.error?.message ?? 'Unknown Error',
        response.data?.error?.code ?? 'UnknownError',
      );
    }
  }
  return err;
}
