import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_HR,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TR,
  ELEMENT_UL,
  TElement,
  TLinkElement,
  TTableElement,
  TText,
} from '@udecode/plate';

/**
 * Text
 */

export type EmptyText = {
  text: '';
};

export type PlainText = {
  text: string;
};

export interface RichText extends TText {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  code?: boolean;
  kbd?: boolean;
  subscript?: boolean;
  // backgroundColor?: CSSProperties['backgroundColor'];
  // fontFamily?: CSSProperties['fontFamily'];
  // color?: CSSProperties['color'];
  // fontSize?: CSSProperties['fontSize'];
  // fontWeight?: CSSProperties['fontWeight'];
}

/**
 * Inline Elements
 */

export interface PlateLinkElement extends TLinkElement {
  type: typeof ELEMENT_LINK;
  children: RichText[];
}

export type PlateInlineElement = PlateLinkElement;
export type PlateInlineDescendant = PlateInlineElement | RichText;
export type PlateInlineChildren = PlateInlineDescendant[];

/**
 * Block props
 */

export interface PlateIndentProps {
  indent?: number;
}

export interface PlateIndentListProps extends PlateIndentProps {
  listStart?: number;
  listStyleType?: string;
}

// export interface PlateLineHeightProps {
//   lineHeight?: CSSProperties['lineHeight'];
// }

// export interface PlateAlignProps {
//   textAlign?: CSSProperties['textAlign'];
// }

// export interface PlateBlockElement extends TElement, PlateIndentListProps, PlateLineHeightProps {
export interface PlateBlockElement extends TElement, PlateIndentListProps {
  id?: string;
}

/**
 * Blocks
 */

export interface PlateParagraphElement extends PlateBlockElement {
  type: typeof ELEMENT_PARAGRAPH;
  children: PlateInlineChildren;
}

export interface PlateH1Element extends PlateBlockElement {
  type: typeof ELEMENT_H1;
  children: PlateInlineChildren;
}

export interface PlateH2Element extends PlateBlockElement {
  type: typeof ELEMENT_H2;
  children: PlateInlineChildren;
}

export interface PlateH3Element extends PlateBlockElement {
  type: typeof ELEMENT_H3;
  children: PlateInlineChildren;
}

export interface PlateBlockquoteElement extends PlateBlockElement {
  type: typeof ELEMENT_BLOCKQUOTE;
  children: PlateInlineChildren;
}

export interface PlateCodeBlockElement extends PlateBlockElement {
  type: typeof ELEMENT_CODE_BLOCK;
  children: PlateCodeLineElement[];
}

export interface PlateCodeLineElement extends TElement {
  type: typeof ELEMENT_CODE_LINE;
  children: PlainText[];
}

export interface PlateTableElement extends TTableElement, PlateBlockElement {
  type: typeof ELEMENT_TABLE;
  children: PlateTableRowElement[];
}

export interface PlateTableRowElement extends TElement {
  type: typeof ELEMENT_TR;
  children: PlateTableCellElement[];
}

export interface PlateTableCellElement extends TElement {
  type: typeof ELEMENT_TD;
  children: PlateNestableBlock[];
}

export interface PlateBulletedListElement extends TElement, PlateBlockElement {
  type: typeof ELEMENT_UL;
  children: PlateListItemElement[];
}

export interface PlateNumberedListElement extends TElement, PlateBlockElement {
  type: typeof ELEMENT_OL;
  children: PlateListItemElement[];
}

export interface PlateListItemElement extends TElement, PlateBlockElement {
  type: typeof ELEMENT_LI;
  children: (PlateListItemContentElement | PlateBulletedListElement | PlateNumberedListElement)[];
}

export interface PlateListItemContentElement extends TElement, PlateBlockElement {
  type: typeof ELEMENT_LIC;
  children: PlateInlineChildren;
}

export interface PlateHrElement extends PlateBlockElement {
  type: typeof ELEMENT_HR;
  children: [EmptyText];
}

export type PlateNestableBlock = PlateParagraphElement;

export type PlateRootBlock =
  | PlateParagraphElement
  | PlateH1Element
  | PlateH2Element
  | PlateH3Element
  | PlateBlockquoteElement
  | PlateCodeBlockElement
  | PlateTableElement
  | PlateBulletedListElement
  | PlateNumberedListElement
  | PlateHrElement;

export type PlateValue = PlateRootBlock[];

export type PlateElement =
  | PlateRootBlock
  | PlateCodeLineElement
  | PlateInlineElement
  | PlateListItemElement
  | PlateListItemContentElement
  | PlateTableCellElement
  | PlateTableRowElement;

export function isPlateElement(obj: unknown): obj is TElement {
  return Boolean((obj as TElement)?.type);
}
