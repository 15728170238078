import { buildDerivedTheme } from './derived-theme';
import { NEUTRAL_LIGHTNESS_DARK, NEUTRAL_LIGHTNESS_LIGHT, Theme } from './types';

export const DEFAULT_THEME: Theme = {
  dark: {
    background: '#1A1D1F',
    primary: '#2A85FF',
    secondary: {
      '01': '#FFBC99',
      '02': '#CABDFF',
      '03': '#B1E5FC',
    },
    danger: '#D83A3A',
    success: '#0EC988',
    text: '#EEE',
    neutralColorLightness: NEUTRAL_LIGHTNESS_DARK,
  },
  light: {
    background: '#FFF',
    primary: '#2A85FF',
    secondary: {
      '01': '#FFBC99',
      '02': '#CABDFF',
      '03': '#B1E5FC',
    },
    danger: '#D83A3A',
    success: '#0EC988',
    text: '#000',
    neutralColorLightness: NEUTRAL_LIGHTNESS_LIGHT,
  },
};

export const DEFAULT_DERIVED_THEME = buildDerivedTheme(DEFAULT_THEME);
