export enum BappoTypeKind {
  ELEMENT = 'ELEMENT',
  ELEMENTS = 'ELEMENTS',
  FILE_SOURCE = 'FILE_SOURCE',
  IMAGE_SOURCE = 'IMAGE_SOURCE',
  RICH_TEXT_PLATE = 'RICH_TEXT_PLATE',
  TEXT = 'TEXT',
  VIDEO_SOURCE = 'VIDEO_SOURCE',
}

export type BappoElementType = {
  typeKind: BappoTypeKind.ELEMENT;
  componentType: string;
};

export type BappoElementsType = {
  typeKind: BappoTypeKind.ELEMENTS;
};

export type BappoFileSourceType = {
  typeKind: BappoTypeKind.FILE_SOURCE;
};

export type BappoImageSourceType = {
  typeKind: BappoTypeKind.IMAGE_SOURCE;
};
export function isBappoImageSourceType(obj: unknown): obj is BappoImageSourceType {
  return (obj as any)?.typeKind === BappoTypeKind.IMAGE_SOURCE;
}

export type BappoRichTextPlateType = {
  typeKind: BappoTypeKind.RICH_TEXT_PLATE;
};

export type BappoTextType = {
  typeKind: BappoTypeKind.TEXT;
};
export function isBappoTextType(obj: unknown): obj is BappoTextType {
  return (obj as any)?.typeKind === BappoTypeKind.TEXT;
}

export type BappoVideoSourceType = {
  typeKind: BappoTypeKind.VIDEO_SOURCE;
};

export type BappoType =
  | BappoElementType
  | BappoElementsType
  | BappoFileSourceType
  | BappoImageSourceType
  | BappoRichTextPlateType
  | BappoTextType
  | BappoVideoSourceType;
