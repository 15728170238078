import { z } from 'zod';

import { buildBaseProductSchema } from '../base-product';
import { buildCourseReleaseSchema } from '../course-release';

export const buildLearnProductSchema = () =>
  buildBaseProductSchema().extend({
    entitlements: z
      .object({
        courses: z.record(buildCourseReleaseSchema().shape.courseId, z.boolean()),
      })
      .optional(),
  });
export type LearnProduct = z.infer<ReturnType<typeof buildLearnProductSchema>>;
