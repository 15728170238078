import { useEvent } from '@innovigo/ui/hooks/useEvent';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type Serializable =
  | string
  | number
  | bigint
  | boolean
  | {
      [key: string | number]: Serializable;
    }
  | null
  | undefined;

export enum StorageStateStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

type State<T> = {
  status: StorageStateStatus;
  data: T | undefined;
  error?: unknown;
};

export function useStorageState<TData extends Serializable>(
  storageKey: string,
  initialState?: TData | (() => TData),
) {
  const asyncStorage = useAsyncStorage(storageKey);

  const [state, setState] = useState<State<TData>>({
    status: StorageStateStatus.NOT_LOADED,
    data: undefined,
  });

  const load = useEvent(async () => {
    if (
      state.status !== StorageStateStatus.NOT_LOADED &&
      state.status !== StorageStateStatus.ERROR
    ) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      status: StorageStateStatus.LOADING,
      error: undefined,
    }));
    try {
      const storedStr = await asyncStorage.getItem();
      let data: TData | undefined;
      if (!storedStr) {
        data = typeof initialState === 'function' ? initialState() : initialState;
      } else {
        try {
          data = JSON.parse(storedStr) as TData;
        } catch {
          data = typeof initialState === 'function' ? initialState() : initialState;
        }
      }
      setState({
        status: StorageStateStatus.ERROR,
        data,
        error: undefined,
      });
      return data;
    } catch (error) {
      console.error(`Failed to load ${storageKey} from storage`);
      setState({
        status: StorageStateStatus.ERROR,
        data: undefined,
        error,
      });
    }
  });

  const persist = useCallback(
    async (newData: TData | undefined) => {
      if (newData !== undefined) {
        await asyncStorage.setItem(JSON.stringify(newData));
      } else {
        await asyncStorage.removeItem();
      }
    },
    [asyncStorage],
  );
  const debouncedPersist = useDebouncedCallback(persist, 1000);
  const save = useEvent(async (newData: TData | undefined) => {
    setState((prevState) => ({
      ...prevState,
      data: newData,
    }));
    await debouncedPersist(newData);
  });

  return {
    ...state,
    load,
    save,
  };
}
