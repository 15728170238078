import { Writable } from 'type-fest';

import { PlateValue } from './rich-text-plate';
import { BappoElement, BappoImageSource, BappoVideoSource } from './values';

export enum BappoDescriptorKind {
  ELEMENT = 'ELEMENT',
  ELEMENTS = 'ELEMENTS',
  IMAGE_SOURCE = 'IMAGE_SOURCE',
  INNOVIGO_FILE = 'INNOVIGO_FILE',
  RICH_TEXT_PLATE = 'RICH_TEXT_PLATE',
  TEXT = 'TEXT',
  VIDEO_SOURCE = 'VIDEO_SOURCE',
}

export type BappoElementDescriptor<
  TProps extends {
    [propsName: string]: BappoDescriptor;
  } = {
    [propsName: string]: BappoDescriptor;
  },
> = Writable<Omit<BappoElement, 'props'>> & {
  descriptorKind: BappoDescriptorKind.ELEMENT;
  props: {
    [propName in keyof TProps]: TProps[propName];
  };
  label: string;
};
export function isBappoElementDescriptor(obj: unknown): obj is BappoElementDescriptor {
  return (obj as any)?.descriptorKind === BappoDescriptorKind.ELEMENT;
}

export type BappoElementsDescriptor = {
  descriptorKind: BappoDescriptorKind.ELEMENTS;
  elementIds: string[];
  elements: {
    [elementId: string]: BappoElementDescriptor;
  };
};
export function isBappoElementsDescriptor(obj: unknown): obj is BappoElementsDescriptor {
  return (obj as any)?.descriptorKind === BappoDescriptorKind.ELEMENTS;
}

export type BappoImageSourceDescriptor = BappoImageSource & {
  descriptorKind: BappoDescriptorKind.IMAGE_SOURCE;
};
export function isBappoImageSourceDescriptor(obj: unknown): obj is BappoImageSourceDescriptor {
  return (obj as any)?.descriptorKind === BappoDescriptorKind.IMAGE_SOURCE;
}

export type BappoInnovigoFileSourceDescriptor = {
  descriptorKind: BappoDescriptorKind.INNOVIGO_FILE;
  fileId: string;
};
export function isBappoInnovigoFileSourceDescriptor(
  obj: unknown,
): obj is BappoInnovigoFileSourceDescriptor {
  return (obj as any)?.descriptorKind === BappoDescriptorKind.INNOVIGO_FILE;
}

export type BappoRichTextPlateDescriptor = {
  descriptorKind: BappoDescriptorKind.RICH_TEXT_PLATE;
  data: PlateValue;
};

export type BappoTextDescriptor = {
  descriptorKind: BappoDescriptorKind.TEXT;
  text: string;
};
export function isBappoTextDescriptor(obj: unknown): obj is BappoTextDescriptor {
  return (obj as any)?.descriptorKind === BappoDescriptorKind.TEXT;
}

export type BappoVideoSourceDescriptor = BappoVideoSource & {
  descriptorKind: BappoDescriptorKind.VIDEO_SOURCE;
};

export type BappoDescriptor =
  | BappoElementDescriptor<{
      [propsName: string]: BappoDescriptor;
    }>
  | BappoElementsDescriptor
  | BappoImageSourceDescriptor
  | BappoInnovigoFileSourceDescriptor
  | BappoRichTextPlateDescriptor
  | BappoTextDescriptor
  | BappoVideoSourceDescriptor;

export function findElementById(
  rootElementDescriptor: BappoElementDescriptor,
  targetElementId: string,
): BappoElementDescriptor | null {
  if (rootElementDescriptor.elementId === targetElementId) {
    return rootElementDescriptor;
  }

  for (const propDescriptor of Object.values(rootElementDescriptor.props)) {
    if (propDescriptor.descriptorKind !== BappoDescriptorKind.ELEMENTS) continue;
    const foundElement = Object.values(propDescriptor.elements).find((ele) => {
      return findElementById(ele, targetElementId);
    });
    if (foundElement) return foundElement;
  }

  return null;
}

export function traverseElement(
  element: BappoElementDescriptor,
  /**
   * Return `false` to stop traversing
   */
  enter: (
    currentElement: BappoElementDescriptor,
    parentElement?: BappoElementDescriptor,
    parentPropName?: string,
  ) => void | false,
) {
  function traverse(
    currentElement: BappoElementDescriptor,
    parentElement?: BappoElementDescriptor,
    parentPropName?: string,
  ) {
    // enter current element
    const ret = enter(currentElement, parentElement, parentPropName);
    if (ret === false) return;

    // recursively update children
    for (const [propName, config] of Object.entries(currentElement.props)) {
      if (config.descriptorKind !== BappoDescriptorKind.ELEMENTS) continue;
      for (const childElement of Object.values(config.elements)) {
        traverse(childElement, currentElement, propName);
      }
    }
  }
  traverse(element);
}

export function findParentElementByChildId(
  rootElement: BappoElementDescriptor,
  childElementId: string,
): {
  parentElement: BappoElementDescriptor | null;
  parentPropName: string | null;
} {
  let targetParentElement: BappoElementDescriptor | null = null;
  let targetParentPropName: string | null = null;
  traverseElement(rootElement, (currentElement, parentElement, parentPropName) => {
    if (currentElement.elementId === childElementId) {
      if (parentElement && parentPropName) {
        targetParentElement = parentElement;
        targetParentPropName = parentPropName;
      }
      return false;
    }
  });
  return {
    parentElement: targetParentElement,
    parentPropName: targetParentPropName,
  };
}
