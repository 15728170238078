import {
  BappoImageSourceDescriptor,
  BappoInnovigoFileSourceDescriptor,
  BappoVideoSourceDescriptor,
} from './descriptors';

export type BappoMargin = {
  t?: number;
  r?: number;
  b?: number;
  l?: number;
};

export class BappoElement<
  TProps extends {
    [propsName: string]: unknown;
  } = {
    [propsName: string]: unknown;
  },
> {
  public readonly elementId: string;
  public readonly componentType: string;
  public readonly props: {
    [propName in keyof TProps]: TProps[propName];
  };
  public readonly containerConfig?: {
    margin?: BappoMargin;
  };

  constructor(params: {
    elementId: string;
    componentType: string;
    props: {
      [propName in keyof TProps]: TProps[propName];
    };
    containerConfig?: {
      margin?: BappoMargin;
    };
  }) {
    this.elementId = params.elementId;
    this.componentType = params.componentType;
    this.props = params.props;
    this.containerConfig = params.containerConfig;
  }
}

export class BappoInnovigoFileSource {
  public readonly fileId: string;

  constructor(descriptor: BappoInnovigoFileSourceDescriptor) {
    this.fileId = descriptor.fileId;
  }
}

export class BappoImageSource {
  public readonly fileId: string;

  constructor(descriptor: BappoImageSourceDescriptor) {
    this.fileId = descriptor.fileId;
  }
}

export class BappoVideoSource {
  public readonly platform: 'vimeo' | 'youtube';
  public readonly videoId: string;
  public readonly hash?: string;

  constructor(descriptor: BappoVideoSourceDescriptor) {
    this.platform = descriptor.platform;
    this.videoId = descriptor.videoId;
    this.hash = descriptor.hash;
  }
}
