import { z } from 'zod';

export const buildBaseProductSchema = () =>
  z.object({
    productId: z.string(),
    name: z.string().min(1).max(64),
    description: z.string().max(512).optional(),
    // Time when it will become available for purchase.
    startDate: z.string(),
    // No end date means it's always available for purchase after start date.
    // Even if it has passed the end date, users who have purchased it will keep their entitlements.
    endDate: z.string().optional(),
  });
export type BaseProduct = z.infer<ReturnType<typeof buildBaseProductSchema>>;
