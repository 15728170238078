import { z } from 'zod';

import { BappoVideoSource } from './bappo-lang/values';
import { ViewRelease, buildViewReleaseSchema } from './view-release';

export const buildPublishedLessonSchema = () =>
  z.object({
    lessonId: z.string(),
    title: z.string().min(1).max(64),
    description: z.string().max(512).optional(),
    durationSeconds: z
      .number()
      .int()
      .positive()
      .lte(5 * 60 * 60) // 5 hours
      .nullable()
      .optional(),
    defaultVideo: z
      .object({
        source: z.object({
          platform: z.enum(['vimeo', 'youtube']),
          videoId: z.string().max(64),
          hash: z.string().max(64).optional(),
        }),
      })
      .optional(),
    /**
     * @deprecated
     */
    notes: z.string().max(512).optional(),
    /**
     * @deprecated
     */
    view: z.any().optional(),
    viewId: buildViewReleaseSchema().shape.viewId.optional(),
  });
export type PublishedLesson = Omit<
  z.infer<ReturnType<typeof buildPublishedLessonSchema>>,
  'defaultVideo'
> & {
  defaultVideo?: {
    source: BappoVideoSource;
  };
  view?: ViewRelease;
};

export const buildPublishedModuleSchema = () =>
  z.object({
    moduleId: z.string(),
    title: z.string().min(1).max(64),
    description: z.string().max(512).optional(),
    lessonIds: z.array(z.string()),
  });
export type PublishedModule = z.infer<ReturnType<typeof buildPublishedModuleSchema>>;

export const buildCourseReleaseSchema = () =>
  z.object({
    courseId: z.string(),
    version: z.number().int().positive(),
    title: z.string().min(1).max(64),
    description: z.string().max(512).optional(),
    coverImage: z.string().min(1).optional(),
    modules: z.array(buildPublishedModuleSchema()),
    lessons: z.array(buildPublishedLessonSchema()),
    createdAt: z.string(),
    updatedAt: z.string(),
  });
export type CourseRelease = z.infer<ReturnType<typeof buildCourseReleaseSchema>>;

export const buildCreateCourseReleaseInputSchema = () =>
  buildCourseReleaseSchema().omit({
    courseId: true,
    version: true,
    createdAt: true,
    updatedAt: true,
  });
export type CreateCourseReleaseInput = z.infer<
  ReturnType<typeof buildCreateCourseReleaseInputSchema>
>;
