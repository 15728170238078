import { z } from 'zod';

import { buildBaseProductSchema } from '../base-product';

export const STORES = ['appStore', 'playStore', 'stripe'] as const;
export type Store = typeof STORES[number];

const buildBasePriceSchema = () =>
  z.object({
    store: z.enum(STORES),
    billingType: z.enum(['one-time', 'recurring']),
    currency: z.string(),
    amountInCents: z.number(),
    storeConfig: z.any(),
    startDate: z.string(),
    endDate: z.string().optional(),
  });
const buildPriceSchema = () =>
  z.discriminatedUnion('billingType', [
    buildBasePriceSchema().extend({
      billingType: z.literal('one-time'),
    }),
    buildBasePriceSchema().extend({
      billingType: z.literal('recurring'),
      billingPeriod: z.enum(['monthly']),
    }),
  ]);

export const buildSubscriptionProductSchema = () =>
  buildBaseProductSchema().extend({
    prices: z.array(buildPriceSchema()),
  });
export type SubscriptionProduct = z.infer<ReturnType<typeof buildSubscriptionProductSchema>>;
