import { useAuth } from '@innovigo/frontend-utils/auth';
import { Navigate, useLocation } from 'react-router-dom';

export function RequireAuth({ children }: { children: React.ReactNode }) {
  const { isLoadingAuthStateComplete, userAttributes } = useAuth();
  const location = useLocation();

  if (!isLoadingAuthStateComplete) return null;

  if (!userAttributes) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  }

  return <>{children}</>;
}
