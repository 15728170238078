import { z } from 'zod';

import { buildCourseReleaseSchema } from './course-release';
import { buildDraftCourseSchema } from './draft-course';
import { buildDraftProductSchema } from './draft-product';

export const buildAppCourseConfigSchema = () =>
  z.object({
    version: z.union([buildCourseReleaseSchema().shape.version, z.literal('$LATEST')]),
    isFreeStartDate: z.string().optional(),
    isFreeEndDate: z.string().optional(),
    startDate: z.string().optional(),
    endDate: z.string().optional(),
  });
export type AppCourseConfig = z.infer<ReturnType<typeof buildAppCourseConfigSchema>>;

export const buildAppReleaseSchema = () =>
  z.object({
    appId: z.string(),
    version: z.number().int().positive(),
    slug: z
      .string()
      .min(1)
      .max(32)
      .regex(/^[a-z0-9][a-z0-9-]*/),
    domain: z.string().min(1).max(64),
    name: z.string().min(1).max(64),
    courses: z.record(buildCourseReleaseSchema().shape.courseId, buildAppCourseConfigSchema()),
    products: z.array(buildDraftProductSchema()).optional(),
    createdAt: z.string(),
    updatedAt: z.string(),
  });
export type AppRelease = z.infer<ReturnType<typeof buildAppReleaseSchema>>;

export const buildCreateAppReleaseInputSchema = () =>
  buildAppReleaseSchema().omit({
    appId: true,
    version: true,
    createdAt: true,
    updatedAt: true,
  });
export type CreateAppReleaseInput = z.infer<ReturnType<typeof buildCreateAppReleaseInputSchema>>;

export const buildPublishAppInputSchema = () =>
  z.object({
    draftAppUpdatedAt: buildAppReleaseSchema().shape.updatedAt,
    draftCoursesUpdatedAt: z.record(
      buildDraftCourseSchema().shape.courseId,
      buildDraftCourseSchema().shape.updatedAt,
    ),
  });
export type PublishAppInput = z.infer<ReturnType<typeof buildPublishAppInputSchema>>;
