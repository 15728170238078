import type { CognitoIdToken } from 'amazon-cognito-identity-js';

import { UserAttributes } from './types';

export function getUserAttributesFromIdToken(idToken: CognitoIdToken): UserAttributes {
  const { payload } = idToken;
  return {
    id: payload.sub,
    email: payload.email,
    firstName: payload.given_name,
    lastName: payload.family_name,
    nickname: payload.nickname,
    userGroups: payload['cognito:groups'],
  };
}
