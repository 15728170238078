import ColorObj from 'color';
import { pick } from 'lodash';

import { Color, DerivedTheme, NeutralColors, Theme } from './types';

export function buildDerivedTheme(theme: Theme): DerivedTheme {
  const darkNeutralColors = deriveNeutralColors(
    theme.dark.background,
    theme.dark.neutralColorLightness,
  );
  const lightNeutralColors = deriveNeutralColors(
    theme.light.background,
    theme.light.neutralColorLightness,
  );

  const darkBackgroundColors = {
    '00': darkNeutralColors['00'],
    '01': darkNeutralColors['01'],
    '02': darkNeutralColors['02'],
  };
  const lightBackgroundColors = {
    '00': lightNeutralColors['01'],
    '01': lightNeutralColors['00'],
    '02': lightNeutralColors['02'],
  };

  return {
    dark: {
      base: pick(theme.dark, ['primary', 'danger', 'success', 'secondary', 'text']),
      neutral: darkNeutralColors,
      background: darkBackgroundColors,
      button: {
        primary: {
          default: {
            background: theme.dark.primary,
            border: theme.dark.primary,
            text: darkNeutralColors['07'],
          },
          hover: {
            background: ColorObj(theme.dark.primary).darken(0.06).hex() as Color,
            border: ColorObj(theme.dark.primary).darken(0.06).hex() as Color,
            text: darkNeutralColors['07'],
          },
          pressing: {
            background: ColorObj(theme.dark.primary).darken(0.12).hex() as Color,
            border: ColorObj(theme.dark.primary).darken(0.12).hex() as Color,
            text: darkNeutralColors['08'],
          },
        },
        secondary: {
          default: {
            background: 'rgba(0, 0, 0, 0)',
            border: darkNeutralColors['02'],
            text: theme.dark.text,
          },
          hover: {
            background: 'rgba(0, 0, 0, 0)',
            border: darkNeutralColors['04'],
            text: theme.dark.text,
          },
          pressing: {
            background: 'rgba(0, 0, 0, 0)',
            border: darkNeutralColors['05'],
            text: theme.dark.text,
          },
        },
      },
      input: {
        default: {
          label: darkNeutralColors['05'],
          icon: darkNeutralColors['04'],
          placeholder: darkNeutralColors['04'],
          background: darkBackgroundColors['02'],
          border: darkBackgroundColors['02'],
          text: theme.dark.text,
        },
        focused: {
          background: darkBackgroundColors['00'],
          border: darkNeutralColors['04'],
          text: theme.dark.text,
        },
      },
      switch: {
        thumb: theme.dark.primary,
        track: ColorObj(theme.dark.primary).desaturate(0.35).lighten(0.2).hex() as Color,
      },
    },
    light: {
      base: pick(theme.light, ['primary', 'danger', 'success', 'secondary', 'text']),
      neutral: lightNeutralColors,
      background: lightBackgroundColors,
      button: {
        primary: {
          default: {
            background: theme.light.primary,
            border: theme.light.primary,
            text: lightNeutralColors['00'],
          },
          hover: {
            background: ColorObj(theme.light.primary).darken(0.06).hex() as Color,
            border: ColorObj(theme.light.primary).darken(0.06).hex() as Color,
            text: lightNeutralColors['00'],
          },
          pressing: {
            background: ColorObj(theme.light.primary).darken(0.12).hex() as Color,
            border: ColorObj(theme.light.primary).darken(0.12).hex() as Color,
            text: lightNeutralColors['00'],
          },
        },
        secondary: {
          default: {
            background: 'rgba(0, 0, 0, 0)',
            border: lightNeutralColors['02'],
            text: theme.light.text,
          },
          hover: {
            background: 'rgba(0, 0, 0, 0)',
            border: lightNeutralColors['04'],
            text: theme.light.text,
          },
          pressing: {
            background: 'rgba(0, 0, 0, 0)',
            border: lightNeutralColors['05'],
            text: theme.light.text,
          },
        },
      },
      input: {
        default: {
          label: lightNeutralColors['05'],
          icon: lightNeutralColors['04'],
          placeholder: lightNeutralColors['04'],
          background: lightBackgroundColors['00'],
          border: lightBackgroundColors['00'],
          text: theme.light.text,
        },
        focused: {
          background: lightBackgroundColors['01'],
          border: lightNeutralColors['04'],
          text: theme.light.text,
        },
      },
      switch: {
        thumb: theme.light.primary,
        track: ColorObj(theme.light.primary).desaturate(0.35).lighten(0.2).hex() as Color,
      },
    },
  };
}

function deriveNeutralColors(background: Color, lightnessArray: readonly number[]) {
  return lightnessArray.reduce((acc, lightness, index) => {
    const key = `${String(index).padStart(2, '0')}` as const;
    acc[key] = ColorObj(background).saturationl(10).lightness(lightness).hex() as Color;
    return acc;
  }, {} as Record<string, Color>) as NeutralColors;
}
