import { Logo } from '@innovigo/consumer-app-sdk/components/Logo';
import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { useResponsiveStyle } from '@innovigo/ui/hooks/useResponseStyle';
import { ScrollView, StyleSheet, View } from 'react-native';

import { AuthForm, AuthFormProps } from './AuthForm';
import { AuthHeader } from './AuthHeader';

export function AuthPage({
  onClose,
  onLogin,
  schema,
  storageKeys,
}: Pick<AuthFormProps, 'onLogin' | 'schema' | 'storageKeys'> & {
  onClose?: () => void;
}) {
  const theme = useTheme();

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: theme.background['01'],
        },
        useResponsiveStyle({
          mobile: {},
          tablet: {
            paddingVertical: 17,
            paddingHorizontal: 21,
          },
          desktop: {
            paddingVertical: 17,
            paddingHorizontal: 21,
          },
        }),
      ]}
    >
      <AuthHeader logo={<Logo />} onClose={onClose} />
      <ScrollView
        contentContainerStyle={styles.scrollContentContainer}
        style={styles.scrollContainer}
      >
        <AuthForm
          containerStyle={styles.form}
          onLogin={onLogin}
          schema={schema}
          storageKeys={storageKeys}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
    paddingTop: 64,
  },
  scrollContentContainer: {
    alignItems: 'center',
  },
  form: {
    maxWidth: 390,
    paddingHorizontal: 32,
    width: '100%',
  },
});
