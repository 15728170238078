import { Icon, IconProps } from '@innovigo/ui/components/Icon';
import { Text } from '@innovigo/ui/components/Text';
import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { useResponsiveStyle } from '@innovigo/ui/hooks/useResponseStyle';
import { Pressable, StyleSheet } from 'react-native';

export function NavLinkBase({
  icon,
  isActive,
  label,
  onPress,
}: {
  icon: IconProps['name'];
  isActive: boolean;
  label: string;
  onPress: () => void;
}) {
  const theme = useTheme();
  const textColor = isActive ? theme.neutral['05'] : theme.neutral['04'];

  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.container,
        useResponsiveStyle({
          mobile: {
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },
          tablet: {
            flexShrink: 0,
            height: 48,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 12,
          },
          desktop: {
            flexShrink: 0,
            height: 48,
            flexDirection: 'row',
            backgroundColor: isActive ? theme.neutral['02'] : undefined,
            borderRadius: 12,
            padding: 12,
          },
        }),
      ]}
    >
      <Icon name={icon} color={textColor} size={24} />
      <Text
        allowFontScaling={false}
        numberOfLines={1}
        style={[
          {
            color: textColor,
          },
          useResponsiveStyle({
            mobile: {
              fontSize: 9,
              marginTop: 6,
            },
            tablet: {
              fontSize: 9,
              lineHeight: 12,
              marginTop: 1,
            },
            desktop: {
              fontSize: 15,
              marginLeft: 12,
            },
          }),
        ]}
      >
        {label}
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
});
