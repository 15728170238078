import useMergedRef from '@react-hook/merged-ref';
import { useRef } from 'react';
import { useController } from 'react-hook-form';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import type { TextInput as RNTextInput } from 'react-native';

import { TextInput, TextInputProps } from '../../TextInput';
import { useTheme } from '../../Theme/ThemeProvider';
import { InputFieldWrapper, InputFieldWrapperProps } from '../InputFieldWrapper';

export type TextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TTransformedValue,
> = UseControllerProps<TFieldValues, TName> &
  Pick<
    TextInputProps<TTransformedValue>,
    | 'autoComplete'
    | 'autoFocus'
    | 'editable'
    | 'multiline'
    | 'onChangeText'
    | 'onEndEditing'
    | 'onSubmitEditing'
    | 'onValueChange'
    | 'placeholder'
    | 'returnKeyType'
    | 'secureTextEntry'
    | 'transform'
  > &
  Pick<InputFieldWrapperProps, 'icon' | 'label'> & {
    onBlur?: () => void;
  };

export function TextField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TTransformedValue,
>({
  autoComplete,
  autoFocus,
  editable,
  icon,
  label,
  multiline,
  onBlur: onBlurProp,
  onChangeText,
  onEndEditing,
  onSubmitEditing,
  onValueChange,
  placeholder,
  returnKeyType,
  secureTextEntry,
  transform,
  ...controllerProps
}: TextFieldProps<TFieldValues, TName, TTransformedValue>) {
  const theme = useTheme();
  const {
    field: { ref, onBlur, onChange, value },
    fieldState,
  } = useController(controllerProps);
  const inputRef = useRef<RNTextInput>(null);
  const mergedRef = useMergedRef(ref, inputRef);

  const handleInputPress = () => {
    inputRef.current?.focus();
  };

  return (
    <InputFieldWrapper
      editable={editable}
      fieldState={fieldState}
      focusInput={handleInputPress}
      icon={icon}
      label={label}
    >
      {({ focused, setFocused }) => {
        return (
          <TextInput
            ref={mergedRef}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            editable={editable}
            multiline={multiline}
            numberOfLines={8}
            onBlur={() => {
              setFocused(false);
              onBlur();
              onBlurProp?.();
            }}
            onChangeText={onChangeText}
            onEndEditing={onEndEditing}
            onFocus={() => setFocused(true)}
            onSubmitEditing={onSubmitEditing}
            onValueChange={(value) => {
              onChange(value);
              onValueChange?.(value);
            }}
            placeholder={placeholder}
            placeholderTextColor={theme.input.default.placeholder}
            returnKeyType={returnKeyType}
            secureTextEntry={secureTextEntry}
            style={[
              styles.input,
              {
                color: focused ? theme.input.focused.text : theme.input.default.text,
              },
            ]}
            transform={transform}
            value={value}
          />
        );
      }}
    </InputFieldWrapper>
  );
}

const styles = StyleSheet.create({
  input: {
    flex: 1,
  },
});
