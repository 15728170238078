import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { StyleSheet, View } from 'react-native';
import type { ViewStyle } from 'react-native';

export function RootContainer({
  children,
  style,
}: {
  children?: React.ReactNode;
  style?: ViewStyle;
}) {
  const theme = useTheme();

  return (
    <View style={[styles.default, { backgroundColor: theme.background['00'] }, style]}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  default: {
    flex: 1,
  },
});
