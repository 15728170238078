import { useCallback, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';
import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';

import { CodeVerificationInput, CodeVerificationInputProps } from './CodeVerificationInput';
import { Text } from './Text';
import { useTheme } from './Theme/ThemeProvider';

export type CodeVerificationFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> &
  Pick<CodeVerificationInputProps, 'onComplete'> & {
    label?: string;
    onResend: () => Promise<void>;
  };

export function CodeVerificationField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  label,
  onComplete,
  onResend,
  ...controllerProps
}: CodeVerificationFieldProps<TFieldValues, TName>) {
  const theme = useTheme();
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController(controllerProps);

  const [resendCooldownSeconds, setResendCooldownSeconds] = useState(60);
  const [isResending, setIsResending] = useState(false);
  const intervalRef = useRef<NodeJS.Timer>();
  const startCountdown = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(
      () =>
        setResendCooldownSeconds((prev) => {
          if (prev > 1) return prev - 1;
          clearInterval(intervalRef.current);
          intervalRef.current = undefined;
          return 0;
        }),
      1000,
    );
  }, []);
  const handleResend = async () => {
    setIsResending(true);
    await onResend();
    setResendCooldownSeconds(60);
    startCountdown();
    setIsResending(false);
  };

  // Start count down when mounted
  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  return (
    <View style={styles.container}>
      <CodeVerificationInput ref={ref} onChange={onChange} onComplete={onComplete} values={value} />
      <View style={styles.errorContainer}>
        <Text
          style={[
            styles.errorText,
            {
              color: theme.base.danger,
            },
          ]}
        >
          {error?.message ?? ''}
        </Text>
      </View>
      <View style={styles.resendContainer}>
        <Pressable
          disabled={resendCooldownSeconds > 0}
          onPress={handleResend}
          style={styles.resendPressable}
        >
          <Text
            style={{
              color: theme.neutral['04'],
            }}
          >
            {resendCooldownSeconds > 0
              ? `${resendCooldownSeconds} seconds to resend again`
              : `Didn't get it? Resend code`}
          </Text>
          <View style={styles.resendSpinner}>{isResending ? <ActivityIndicator /> : null}</View>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  errorContainer: {
    height: 16,
  },
  errorText: {
    fontSize: 12,
    lineHeight: 16,
  },
  resendContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
  },
  resendPressable: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  resendSpinner: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
  },
});
