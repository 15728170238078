import { useState } from 'react';
import type { ControllerFieldState } from 'react-hook-form';
import { Platform, Pressable, StyleSheet, View } from 'react-native';

import { Icon, IconProps } from '../../Icon';
import { Text } from '../../Text';
import { useTheme } from '../../Theme/ThemeProvider';

export type InputFieldWrapperProps = {
  autoFocus?: boolean;
  children?:
    | React.ReactNode
    | ((fieldState: {
        focused: boolean;
        setFocused: (focused: boolean) => void;
      }) => React.ReactNode);
  editable?: boolean;
  fieldState: ControllerFieldState;
  focusInput?: () => void;
  icon?: IconProps['name'];
  label?: string;
};

export function InputFieldWrapper({
  autoFocus = false,
  children,
  editable = true,
  fieldState: { error },
  focusInput,
  icon,
  label,
}: InputFieldWrapperProps) {
  const theme = useTheme();
  const [focused, setFocused] = useState(autoFocus);

  return (
    <View style={styles.container}>
      {label ? (
        <View style={styles.labelContainer}>
          <Text
            style={[
              styles.labelText,
              {
                color: theme.input.default.label,
              },
            ]}
          >
            {label}
          </Text>
          <Icon name="info" color={theme.input.default.icon} size={16} />
        </View>
      ) : null}
      <Pressable
        disabled={!editable}
        onFocus={focusInput}
        onPress={focusInput}
        style={[Platform.OS === 'web' && styles.pressableWeb]}
      >
        <View
          style={[
            styles.inputContainer,
            {
              backgroundColor: focused
                ? theme.input.focused.background
                : theme.input.default.background,
              borderColor: focused ? theme.input.focused.border : theme.input.default.border,
            },
          ]}
        >
          {icon ? (
            <View style={styles.iconContainer}>
              <Icon name={icon} color={theme.input.default.icon} size={24} />
            </View>
          ) : null}
          {typeof children === 'function'
            ? children({
                focused,
                setFocused,
              })
            : children}
        </View>
      </Pressable>
      <View style={styles.errorContainer}>
        <Text
          style={[
            styles.errorText,
            {
              color: theme.base.danger,
            },
          ]}
        >
          {error?.message ?? ''}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
  pressableWeb: {
    // @ts-ignore web-only style
    cursor: 'default',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    fontSize: 15,
    lineHeight: 24,
    marginRight: 4,
  },
  inputContainer: {
    flexShrink: 0,
    minHeight: 48,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 12,
    borderStyle: 'solid',
    borderWidth: 2,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  iconContainer: {
    flexShrink: 0,
    width: 24,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  errorContainer: {
    height: 16,
  },
  errorText: {
    fontSize: 12,
    lineHeight: 16,
  },
});
