import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { useState } from 'react';

const MAX_AGE = 1000 * 60 * 60 * 24 * 7; // 7 days

export function QueryClientProvider({
  children,
  onError,
  storageKey,
}: {
  children?: React.ReactNode;
  onError?: (err: unknown) => void;
  storageKey: string;
}) {
  const [{ queryClient, persister }] = useState(() => {
    const queryClient = new QueryClient({
      defaultOptions: {
        mutations: {
          onError: (err) => {
            onError?.(err);
          },
        },
        queries: {
          cacheTime: MAX_AGE,
          onError: (err) => {
            onError?.(err);
          },
        },
      },
    });
    const persister = createAsyncStoragePersister({
      storage: AsyncStorage,
      key: storageKey,
      throttleTime: 1000,
    });
    return {
      queryClient,
      persister,
    };
  });

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister, maxAge: MAX_AGE }}
    >
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  );
}
