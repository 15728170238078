import type { IconProps } from '@innovigo/ui/components/Icon';
import { NavLinkBase } from '@innovigo/frontend-utils/navbar/NavLinkBase';
import { useEvent } from '@innovigo/ui/hooks/useEvent';
import { useMemo } from 'react';
import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

export function NavLink({
  icon,
  label,
  to,
}: {
  icon: IconProps['name'];
  label: string;
  to: string;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const path = useResolvedPath(to);
  const isActive = useMemo(() => {
    const locationPathname = location.pathname.toLowerCase();
    const toPathname = path.pathname.toLowerCase();
    return (
      locationPathname === toPathname ||
      (locationPathname.startsWith(toPathname) &&
        locationPathname.charAt(toPathname.length) === '/')
    );
  }, [location.pathname, path.pathname]);

  const handlePress = useEvent(() => {
    navigate(to);
  });

  return <NavLinkBase icon={icon} isActive={isActive} label={label} onPress={handlePress} />;
}
