import { CreatorApiPrivateClient } from '@innovigo/creator-api-client';
import { useAuth } from '@innovigo/frontend-utils/auth';
import { createContext, useContext, useMemo } from 'react';
import invariant from 'tiny-invariant';

type ApiDataContextValue = {
  authenticatedClient: CreatorApiPrivateClient | null;
};

const ApiDataContext = createContext<ApiDataContextValue>({
  authenticatedClient: null,
});

export function CreatorApiProvider({
  appConfig,
  children,
}: {
  appConfig?: {
    apiEndpoint: string;
  };
  children?: React.ReactNode;
}) {
  const { getAccessToken } = useAuth();

  const contextValue = useMemo(() => {
    const authenticatedClient = new CreatorApiPrivateClient({
      getAccessToken,
      endpoint: appConfig?.apiEndpoint,
    });
    return {
      authenticatedClient,
    };
  }, [appConfig?.apiEndpoint, getAccessToken]);

  return <ApiDataContext.Provider value={contextValue}>{children}</ApiDataContext.Provider>;
}

export function useAuthenticatedApiClient() {
  const { authenticatedClient } = useContext(ApiDataContext);
  invariant(authenticatedClient, 'Component should not be rendered when not authenticated');
  return authenticatedClient;
}
