import { forwardRef } from 'react';
import { Platform, TextInput as RNTextInput, StyleSheet } from 'react-native';
import type { TextInputProps as RNTextInputProps } from 'react-native';

import { useTheme } from '../Theme/ThemeProvider';

export type TextInputProps<TTransformedValue> = Omit<RNTextInputProps, 'value'> & {
  onValueChange?: (transformedValue: TTransformedValue) => void;
  transform?: {
    format?: (value: TTransformedValue) => string;
    parse?: (value: string) => TTransformedValue;
  };
  value: TTransformedValue;
};

export const TextInput = forwardRef(function TextInput<TTransformedValue>(
  {
    onChangeText,
    onValueChange,
    transform,
    value,
    ...textInputProps
  }: TextInputProps<TTransformedValue>,
  ref: React.Ref<RNTextInput>,
) {
  const theme = useTheme();

  const handleChangeText = (text: string) => {
    onChangeText?.(text);
    onValueChange?.(
      transform?.parse ? transform.parse(text) : (text as unknown as TTransformedValue),
    );
  };

  return (
    <RNTextInput
      ref={ref}
      autoCapitalize="none"
      autoComplete="off"
      autoCorrect={false}
      {...textInputProps}
      onChangeText={handleChangeText}
      style={[
        styles.default,
        {
          color: theme.base.text,
        },
        textInputProps.style,
      ]}
      value={transform?.format ? transform.format(value) : (value as unknown as string)}
    />
  );
}) as <TTransformedValue>(
  props: TextInputProps<TTransformedValue> & {
    ref?: React.Ref<RNTextInput>;
  },
) => JSX.Element;

const styles = StyleSheet.create({
  default: {
    fontFamily: 'InnovigoMain',
    ...(Platform.OS === 'web'
      ? {
          outlineStyle: 'none',
          outlineWidth: 0,
        }
      : {}),
  },
});
