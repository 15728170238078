import { impossible } from '@innovigo/types';
import { useColorScheme } from '@innovigo/ui/components/ColorScheme';
import { Icon } from '@innovigo/ui/components/Icon';
import { Text } from '@innovigo/ui/components/Text';
import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { useResponsiveStyle } from '@innovigo/ui/hooks/useResponseStyle';
import { useSystemColorScheme } from '@innovigo/ui/hooks/useSystemColorScheme';
import { Pressable, StyleSheet, View } from 'react-native';

import { useSetColorSchemePreference } from '../color-scheme-preference';

export function ColorSchemeSwitch() {
  const colorScheme = useColorScheme();
  const systemColorScheme = useSystemColorScheme();
  const setColorSchemePreference = useSetColorSchemePreference();
  const theme = useTheme();
  const inactiveTextColor = theme.neutral['04'];
  const activeTextColor = theme.neutral['07'];

  return (
    <Pressable
      onPress={() =>
        setColorSchemePreference(
          colorScheme === systemColorScheme
            ? colorScheme === 'dark'
              ? 'light'
              : colorScheme === 'light'
              ? 'dark'
              : impossible(colorScheme)
            : undefined,
        )
      }
      style={[
        styles.container,
        {
          backgroundColor:
            colorScheme === 'dark'
              ? theme.neutral['00']
              : colorScheme === 'light'
              ? theme.neutral['02']
              : impossible(colorScheme),
        },
      ]}
    >
      <View
        style={[
          styles.textContainer,
          useResponsiveStyle({
            mobile: {
              display: 'none',
            },
            tablet: {
              display: 'none',
            },
            desktop: {},
          }),
        ]}
      >
        <Icon name="wb-sunny" color={inactiveTextColor} size={24} />
        <Text
          style={[
            styles.text,
            {
              color: inactiveTextColor,
            },
          ]}
        >
          Light
        </Text>
      </View>
      <View
        style={[
          styles.textContainer,
          useResponsiveStyle({
            mobile: {
              display: 'none',
            },
            tablet: {
              display: 'none',
            },
            desktop: {},
          }),
        ]}
      >
        <Icon name="nightlight-round" color={inactiveTextColor} size={24} />
        <Text
          style={[
            styles.text,
            {
              color: inactiveTextColor,
            },
          ]}
        >
          Dark
        </Text>
      </View>
      <View
        style={[
          styles.textContainer,
          styles.handle,
          {
            backgroundColor:
              colorScheme === 'dark'
                ? theme.neutral['02']
                : colorScheme === 'light'
                ? theme.neutral['01']
                : impossible(colorScheme),
          },
          useResponsiveStyle({
            mobile: {
              left: 4,
              right: 4,
            },
            tablet: {
              left: 4,
              right: 4,
            },
            desktop: {
              width: 142,
              ...(colorScheme === 'dark'
                ? {
                    right: 4,
                  }
                : colorScheme === 'light'
                ? {
                    left: 4,
                  }
                : {}),
            },
          }),
        ]}
      >
        <Icon
          name={
            colorScheme === 'dark'
              ? 'nightlight-round'
              : colorScheme === 'light'
              ? 'wb-sunny'
              : impossible(colorScheme)
          }
          color={activeTextColor}
          size={24}
        />
        <Text
          style={[
            styles.text,
            {
              color: activeTextColor,
            },
            useResponsiveStyle({
              mobile: {
                display: 'none',
              },
              tablet: {
                display: 'none',
              },
              desktop: {},
            }),
          ]}
        >
          {colorScheme === 'dark'
            ? 'Dark'
            : colorScheme === 'light'
            ? 'Light'
            : impossible(colorScheme)}
        </Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 0,
    flexBasis: 'auto',
    height: 48,
    flexDirection: 'row',
    borderRadius: 24,
    padding: 4,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
  },
  handle: {
    position: 'absolute',
    top: 4,
    bottom: 4,
    shadowColor: '#000',
    shadowOffset: {
      height: 4,
      width: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 8,
  },
  text: {
    fontSize: 15,
    lineHeight: 24,
    marginLeft: 8,
  },
});
