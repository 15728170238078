import { Icon } from '@innovigo/ui/components/Icon';
import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { Pressable, SafeAreaView, StyleSheet, View } from 'react-native';

export function AuthHeader({ logo, onClose }: { logo?: React.ReactNode; onClose?: () => void }) {
  const theme = useTheme();
  return (
    <SafeAreaView style={styles.container}>
      {/* another View since SafeAreaView cannot have padding */}
      <View style={styles.innerContainer}>
        {logo}
        {onClose ? (
          <Pressable onPress={onClose}>
            <Icon name="close" color={theme.neutral['04']} size={30} />
          </Pressable>
        ) : (
          <View />
        )}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexShrink: 0,
    height: 79,
  },
  innerContainer: {
    flex: 1,
    paddingLeft: 30,
    paddingRight: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
