import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import type {
  IconProps as ExpoIconProps,
  Icon as IconType,
} from '@expo/vector-icons/build/createIconSet';

import { useTheme } from '../Theme/ThemeProvider';

type MaterialGlyph = typeof MaterialIcons extends IconType<infer T, any> ? T : never;
export type IconProps = ExpoIconProps<MaterialGlyph>;

export function Icon(props: IconProps) {
  const theme = useTheme();

  return <MaterialIcons {...props} color={props.color ?? theme.base.text} />;
}
