import { z } from 'zod';

import { AppRelease, buildAppReleaseSchema } from './app-release';

export const buildDraftAppSchema = () =>
  buildAppReleaseSchema()
    .omit({
      version: true,
    })
    .extend({
      orgId: z.string(),
      slug: buildAppReleaseSchema().shape.slug.optional(),
      domain: buildAppReleaseSchema().shape.domain.optional(),
      courses: buildAppReleaseSchema().shape.courses.optional(),
      activeVersion: buildAppReleaseSchema().shape.version.optional(),
      adminUserEmails: z.array(z.string().email()).optional(),
    });
export type DraftApp = z.infer<ReturnType<typeof buildDraftAppSchema>>;

export const buildCreateDraftAppInputSchema = () =>
  buildDraftAppSchema().pick({
    name: true,
    slug: true,
  });
export type CreateDraftAppInput = z.infer<ReturnType<typeof buildCreateDraftAppInputSchema>>;

export const buildUpdateDraftAppInputSchema = () =>
  buildDraftAppSchema()
    .pick({
      name: true,
      courses: true,
      products: true,
    })
    .partial();
export type UpdateDraftAppInput = z.infer<ReturnType<typeof buildUpdateDraftAppInputSchema>>;

export const buildCreateAdminUserInputSchema = () =>
  z.object({
    email: z.string().email(),
  });
export type CreateAdminUserInput = z.infer<ReturnType<typeof buildCreateAdminUserInputSchema>>;

export type DraftAppWithActiveAppRelease = DraftApp & {
  activeAppRelease?: AppRelease;
};
