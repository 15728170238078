import { z } from 'zod';

import { BappoElementDescriptor, BappoElementsDescriptor } from './bappo-lang/descriptors';

export const buildViewReleaseSchema = () =>
  z.object({
    viewId: z.string(),
    version: z.number().int().positive(),
    name: z.string().min(1).max(64).optional(),
    // TODO: view schema
    root: z.any(),
    createdAt: z.string(),
    updatedAt: z.string(),
  });
export type ViewRelease = z.infer<ReturnType<typeof buildViewReleaseSchema>> & {
  root?: BappoElementDescriptor<{
    children: BappoElementsDescriptor;
  }>;
};

export const buildCreateViewReleaseInputSchema = () =>
  buildViewReleaseSchema().omit({
    viewId: true,
    version: true,
    createdAt: true,
    updatedAt: true,
  });
export type CreateViewReleaseInput = z.infer<ReturnType<typeof buildCreateViewReleaseInputSchema>>;
