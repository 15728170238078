import { createRef, forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import type { TextInput as RNTextInput } from 'react-native';

import { Text } from './Text';
import { TextInput } from './TextInput';
import { useTheme } from './Theme/ThemeProvider';

export interface CodeVerificationInputProps {
  // Number of characters
  fields?: number;
  // Callback function to be called when input changed
  onChange?: (value: string[]) => void;
  // Callback function to be called when user type all the code
  onComplete: (code: string) => void;
  // array of single-character strings
  values?: string[];
}

export type CodeVerificationInputHandle = {
  blur(): void;
  focus(): void;
};

export const CodeVerificationInput = forwardRef(function CodeVerificationInput(
  { fields = 6, values = [], onChange, onComplete }: CodeVerificationInputProps,
  ref: React.Ref<CodeVerificationInputHandle>,
) {
  const theme = useTheme();

  const sanitizedValues = useMemo(() => {
    return [
      ...values.slice(0, fields),
      ...Array.from<string>({
        length: Math.max(0, fields - values.length),
      }).fill(''),
    ];
  }, [fields, values]);

  const [focusIndex, setFocusIndex] = useState<number | null>(null);

  //initial refs array, later will be used to control focus
  const inputRefs = Array.from({
    length: fields,
  }).map(() => createRef<RNTextInput>());
  const focusInput = (index: number) => {
    if (!inputRefs[index]) return;
    inputRefs[index].current?.focus();
  };

  useImperativeHandle(ref, () => {
    return {
      blur: () => {
        if (focusIndex != null) {
          inputRefs[focusIndex]?.current?.blur();
        }
      },
      focus: () => {
        focusInput(Math.max(0, sanitizedValues.findIndex(Boolean)));
      },
    };
  });

  /**
   * This function will be used to handle every character type by user
   * @param text the input of user, could be single character or multiple characters or backspace or delete
   * @param idx index number of the input, used to control focus
   */
  const handleChangeText = (text: string, idx: number) => {
    const sanitizedText = text.replace(/[^\d]/gi, '').slice(0, fields - idx);
    const newValues = [
      ...sanitizedValues.slice(0, idx),
      ...sanitizedText.split(''),
      ...sanitizedValues.slice(idx + sanitizedText.length),
    ];
    const idxToFocus = Math.min(fields - 1, idx + sanitizedText.length);
    focusInput(idxToFocus);
    onChange?.(newValues);
    const newCode = newValues.join('');
    if (onComplete && newCode.length === fields) {
      onComplete(newCode);
    }
  };
  const handleKeyPress = (key: string, idx: number) => {
    switch (key) {
      case 'ArrowLeft':
        focusInput(idx - 1);
        break;
      case 'ArrowRight':
        focusInput(idx + 1);
        break;
      case 'Backspace':
        focusInput(idx - 1);
        if (onChange && sanitizedValues[idx]) {
          onChange([...sanitizedValues.slice(0, idx), '', ...sanitizedValues.slice(idx + 1)]);
        }
        break;
      default:
        break;
    }
  };

  return (
    <View style={styles.container}>
      {sanitizedValues.map((val, idx) => (
        <View
          key={idx}
          style={[
            styles.inputContainer,
            {
              backgroundColor: theme.background['00'],
              borderColor: focusIndex === idx ? theme.input.focused.border : theme.background['00'],
            },
          ]}
        >
          <TextInput
            ref={inputRefs[idx]}
            blurOnSubmit={false}
            caretHidden={true}
            onBlur={() => setFocusIndex(null)}
            onChangeText={(text: string) => {
              handleChangeText(text, idx);
            }}
            onFocus={() => setFocusIndex(idx)}
            onKeyPress={(event) => {
              handleKeyPress(event.nativeEvent.key, idx);
            }}
            selectTextOnFocus={true}
            style={styles.input}
            value={val}
          />
          {/* caretHidden does not work on web */}
          {Platform.OS === 'web' ? (
            <View
              pointerEvents="none"
              style={[
                StyleSheet.absoluteFill,
                styles.webInputCover,
                {
                  backgroundColor: theme.background['00'],
                },
              ]}
            >
              <Text style={[styles.webInputCoverText]}>{val}</Text>
            </View>
          ) : null}
        </View>
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputContainer: {
    borderRadius: 12,
    borderWidth: 2,
    overflow: 'hidden',
  },
  input: {
    fontSize: 20,
    height: 44,
    width: 44,
    textAlign: 'center',
  },
  webInputCover: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
  },
  webInputCoverText: {
    fontSize: 20,
  },
});
